<!-- Entire component as the drag and drop area -->
<div
  class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
  (dragover)="preventDefault($event)"
  (dragenter)="preventDefault($event)"
  (drop)="onFileDropped($event)"
>
  <!-- Error Message -->
  <div class="row" *ngIf="errorMessages.length > 0">
    <div class="col">
      <div class="alert alert-danger">
        <ul>
          <li *ngFor="let errorMsg of errorMessages">{{ errorMsg }}</li>
        </ul>
      </div>
    </div>
  </div>

  <!-- File Upload Form -->
  <div class="row">
    <form (submit)="onSubmit($event)">
      <div class="row mb-4">
        <div class="col">
          <input
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            multiple
            accept=".esx"
          />
        </div>
      </div>
      <div class="row gx-5">
        <div class="col-3">
          <button type="submit">Submit</button>
        </div>
        <div class="col">
          <button type="button" (click)="clearForm()">Clear</button>
        </div>
      </div>
    </form>
  </div>

  <!-- File List -->

  <div class="row row-cols-2 gy-4 mt-2">
    <ul class="w-100">
      <li
        *ngFor="let file of fileList; index as i"
        class="d-flex justify-content-between align-items-center mb-2"
      >
        <div class="flex-grow-1 me-2">
          {{ file.name }}
        </div>
        <div>
          <button (click)="removeFile(i)">Remove</button>
        </div>
      </li>
    </ul>
  </div>
</div>
