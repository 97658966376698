import { Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-cvi',
  templateUrl: './cvi.component.html',
})
export class CviComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;

  selectedFiles!: FileList;
  fileList: File[] = [];
  errorMessages: string[] = [];

  constructor(private http: HttpClient) { }

  preventDefault(event: DragEvent) {
    event.preventDefault();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.fileList.push(input.files[i]);
      }
    }
  }

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const transfer = event.dataTransfer;

    if (transfer && transfer.files) {
      for (let i = 0; i < transfer.files.length; i++) {
        const file = transfer.files.item(i);
        if (file) {
          this.fileList.push(file);
        }
      }
    }
  }

  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }

  clearForm() {
    this.errorMessages = []
    this.fileList = [];
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';  // Reset the input element
    }
  }

  onSubmit(event: Event) {
    console.log("Submit button clicked!");
    event.preventDefault();

    if (!this.fileList.length) {
      console.log('No files selected.');
      return;
    }

    const formData = new FormData();

    this.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    });

    this.http.post('https://velaspan-backend.thrashers.io/e2x', formData, {
      responseType: 'blob',
      observe: 'response'
    }).subscribe({
      next: (response) => {
        console.log(response.headers);  // Debug line to print all headers
        const contentDisposition = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', contentDisposition);  // Debug line to print this specific header
        let fileName = 'unknown';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?\b/);
          if (match && match[1]) {
            fileName = match[1];
          }
        }

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/octet-stream' });
          const blobUrl = window.URL.createObjectURL(blob);

          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.download = fileName; // extracted filename
          anchor.click();

          window.URL.revokeObjectURL(blobUrl); // free up memory
          this.clearForm();
        }
      },
      error: (errorResponse) => {
        if (errorResponse.error instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const errorResult = JSON.parse(reader.result as string);
            if (errorResult && 'errors' in errorResult && Array.isArray(errorResult.errors)) {
              this.errorMessages = errorResult.errors;
            } else {
              this.errorMessages = ['An undefined error occurred'];
            }
            console.error('Parsed error:', this.errorMessages);
          };
          reader.readAsText(errorResponse.error);
        } else {
          this.errorMessages = ['Request failed'];
          console.error('Request failed', errorResponse);
        }
      }
    });
  }

}
